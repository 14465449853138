
import React from 'react';
import Filter from '../../../Auxiliar/Filter';
import RelacionamentosInternos from './RelacionamentosInternos';

const SelecionarJsRelacionamentosInternos = () => {
    const [search, setSearch] = React.useState(false)
    const [dateOf, setDateOf] = React.useState('')
    const [dateFor, setDateFor] = React.useState('')
    const [options, setOptions] = React.useState({
        relacionamentosInternos: { value: true, label: 'Tabela de Relacionamentos', checked: true },
    })

    return (
        <div className="card">
            <div className="card-body" id="card">
                <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Relacionamentos Internos</h4>
                <Filter setDateOf={setDateOf} setDateFor={setDateFor} options={options} setOptions={setOptions} setSearch={setSearch} />
                <div>
                    <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados os relacionamentos internos, feitos entre ambientes.</p>
                </div>
                <div className="row">
                    {options.relacionamentosInternos.value &&
                        <RelacionamentosInternos dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
                </div>
            </div>
        </div>
    )
}

export default SelecionarJsRelacionamentosInternos
