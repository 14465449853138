import React from "react";
import { Link } from "react-router-dom";

import { MdReply } from "react-icons/md";
import { GET, POST } from "../../../../Auxiliar/Requests";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { NAME, URLadmin } from "../../../../variables";
import { maskCep } from "../../../../Auxiliar/Masks";
import { FaTrash } from "react-icons/fa";

const ConvidarOrganizacao = () => {
  const history = useHistory();
  const [errorMsg, setErrorMsg] = React.useState(null);
  const [loadingCep, setLoadingCep] = React.useState(false);
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [form, setForm] = React.useState({
    organization_super_id: "",
    destino_id: "",
    ponto_id: "",
    name: "",
    email: "",
    commercial_name: "",
    company_name: "",
    cep: "",
    street: "",
    number: "",
    neighborhood: "",
    complement: "",
    city: "",
    state: "",
    selected_residuos: [],
    selected_rejeitos: [],
  });
  const [organizations, setOrganizations] = React.useState([]);
  const [destinations, setDestinations] = React.useState([]);
  const [wasteRejects, setWasteRejects] = React.useState([]);

  React.useEffect(() => {
    getRR();
    getData();
  }, []);

  //-------------------------*-------------------------
  const getData = async () => {
    fetch(`${URLadmin}api/auth/admin/index_organizations?connection=${NAME}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then(async (responseLog) => {
        try {
          let response = await responseLog.json();
          if (response.response) {
            setOrganizations(response?.organizations);
          } else {
            history.push("/relacionamentos_internos");
          }
        } catch (err) {
          history.push("/relacionamentos_internos");
          console.log(err);
        }
      })
      .catch((err) => {
        history.push("/relacionamentos_internos");
        console.log(err);
      });
  };

  const getRR = async () => {
    const response = await GET({ url: `index_destino` });
    if (response.residuos_rejeitos) {
      setWasteRejects(response.residuos_rejeitos);
    }
  };

  const getDestinos = async () => {
    const response = await GET({ url: `index_simple_destinos` });
    if (response.response) {
      setDestinations(response.destinos);
    }
  };

  const [options, setOptions] = React.useState([
    {
      label: "Convidar destino",
      is_checked: true,
      use_as: false,
      name: "destino",
    },
    {
      label: "Convidar ponto de coleta",
      is_checked: false,
      use_as: false,
      name: "ponto de coleta",
    },
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleOptionChange = (index) => {
    const updatedOptions = options.map((option, i) => ({
      ...option,
      is_checked: i === index,
      use_as: false,
    }));
    setOptions(updatedOptions);
  };

  const handleOrganizationChange = (e) => {
    handleInputChange(e);
    fetch(`${URLadmin}api/auth/admin/show_organization/${e.target.value}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }).then(async (responseLog) => {
      try {
        let response = await responseLog.json();
        if (response.response) {
          let { organization } = response;
          setForm((prevForm) => ({
            ...prevForm,
            company_name: organization.company_name,
            commercial_name: organization.commercial_name,
            cep: organization.cep,
            city: organization.city,
            state: organization.state,
            street: organization.street,
            number: organization.number,
            neighborhood: organization.nbhd,
            complement: organization.comp,
          }));
        }
        return;
      } catch (err) {
        console.error(err);
      }
    });
  };

  const handleUseAsChange = (index) => {
    const updatedOptions = options.map((option, i) => ({
      ...option,
      use_as: i === index ? !option.use_as : option.use_as,
    }));
    setOptions(updatedOptions);

    if (
      index === 0 &&
      destinations.length < 1 &&
      updatedOptions[index].use_as
    ) {
      getDestinos();
      return;
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        destino_id: "",
        name: "",
        email: "",
      }));
    }
  };

  const handleDestinoChange = (e) => {
    handleInputChange(e);

    let selectedDestination = destinations.find(
      (obj) => obj.id === e.target.value
    );
    setForm((prevForm) => ({
      ...prevForm,
      name: selectedDestination?.user.name,
      email: selectedDestination?.user.email,
    }));
  };

  const handleCepChange = (e) => {
    e.target.value = maskCep(e.target.value);
    handleInputChange(e);

    let cep = e.target.value.replace("-", "");
    if (cep.length === 8) {
      setLoadingCep(true);
      fetch(`https://viacep.com.br/ws/${cep}/json/`, {
        method: "GET",
        headers: {},
      })
        .then(async (responseLog) => {
          try {
            let respCEP = await responseLog.json();
            setLoadingCep(false);
            setForm((prevForm) => ({
              ...prevForm,
              street: respCEP.logradouro,
              neighborhood: respCEP.bairro,
              city: respCEP.localidade,
              state: respCEP.uf,
            }));
          } catch (err) {
            console.log("err", err);
            setForm((prevForm) => ({
              ...prevForm,
              street: "",
              neighborhood: "",
              city: "",
              state: "",
            }));
            setErrorMsg("Algo deu errado. Seu CEP está correto?");
            setLoadingCep(false);
          }
        })
        .catch((err) => {
          setErrorMsg(
            "Erro ao pegar resposta do servidor. Você está conectado a internet?"
          );
          setLoadingCep(false);
        });
    }
  };

  const sendInvitation = () => {
    alert("Função em desenvolvimento");
    console.log(form);
  };

  return (
    <div className="card">
      <div className="card-body" id="card">
        <h4
          className="mt-0 header-title"
          style={{ fontSize: "1.5rem", color: "black" }}
        >
          Cadastro de relacionamento
        </h4>
        <p>
          Convide outra organização para fazer parte do seu ambiente, atuando
          como ponto de coleta ou destino.
        </p>
        {errorMsg && (
          <div
            className="alert alert-danger alert-dismissible fade show mb-0"
            role="alert"
          >
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => setErrorMsg(null)}
            ></button>

            <p style={{ marginBottom: 0, whiteSpace: "pre" }}>{errorMsg}</p>
          </div>
        )}
        <div className="form-group row">
          <label className="col-sm-12 col-form-label">
            Organização <b style={{ color: "red" }}>*</b>
          </label>
          <div className="col-sm-12">
            <select
              className="form-control"
              name="organization_super_id"
              value={form.organization_super_id}
              onChange={(e) => handleOrganizationChange(e)}
            >
              <option value={""}>Selecionar organização</option>
              {organizations.map((item, index) => (
                <option value={item.id} key={index}>
                  {item.commercial_name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row ms-1 pt-2">
          {options.map((item, index) => (
            <div key={index} className="form-check col-12 col-sm-4">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id={`flexRadioDefault${index}`}
                disabled
                checked={item.is_checked}
                onChange={() => handleOptionChange(index)}
              />
              <label
                className="form-check-label"
                htmlFor={`flexRadioDefault${index}`}
              >
                {item.label}
              </label>
            </div>
          ))}
        </div>
        <div className="row ms-1 pt-2">
          {options.map(
            (option, index) =>
              option.is_checked && (
                <div key={index} className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    onChange={() => handleUseAsChange(index)}
                    checked={option.use_as}
                  />
                  <label className="form-check-label">
                    Usar como {option.name} já existente
                  </label>
                </div>
              )
          )}
        </div>
        {options.map(
          (option, index) =>
            option.use_as && (
              <div key={index}>
                {index === 0 && (
                  <div className="form-group row">
                    <div className="col-sm-12">
                      <select
                        className="form-control"
                        onChange={(e) => handleDestinoChange(e)}
                        value={form.destino_id}
                        name="destino_id"
                      >
                        <option value={""}>Selecionar destino</option>
                        {destinations?.map((item, index) => (
                          <option value={item.id} key={index}>
                            {item.razao}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
                {index === 1 && (
                  <div className="form-group row">
                    <div className="col-sm-12">
                      <select
                        className="form-control"
                        onChange={handleInputChange}
                        value={form.ponto_id}
                      >
                        <option value={""}>Selecionar ponto de coleta</option>
                        <option value={"id aqui"}>Ponto 1</option>
                        <option value={"id2 aqui"}>Ponto 2</option>
                      </select>
                    </div>
                  </div>
                )}
              </div>
            )
        )}
        <div className="form-group row">
          <label className="col-sm-12 col-form-label">
            Nome Responsável <b style={{ color: "red" }}>*</b>
          </label>
          <div className="col-sm-12">
            <input
              type="text"
              className="form-control"
              onChange={handleInputChange}
              value={form.name}
              name="name"
              placeholder="Fulano da Silva"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-12 col-form-label">
            E-mail Responsável <b style={{ color: "red" }}>*</b>
          </label>
          <div className="col-sm-12">
            <input
              type="email"
              className="form-control"
              onChange={handleInputChange}
              value={form.email}
              name="email"
              placeholder="email@exemplo.com"
            />
          </div>
        </div>
        <hr />
        <div className="form-group row">
          <label className="col-sm-12 col-form-label">
            Razão social<b style={{ color: "red" }}>*</b>
          </label>
          <div className="col-sm-12">
            <input
              type="text"
              className="form-control"
              onChange={handleInputChange}
              value={form.company_name}
              name="company_name"
              placeholder="Razão Social"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-12 col-form-label">
            Nome fantasia<b style={{ color: "red" }}>*</b>
          </label>
          <div className="col-sm-12">
            <input
              type="text"
              className="form-control"
              onChange={handleInputChange}
              value={form.commercial_name}
              name="commercial_name"
              placeholder="Nome Fantasia"
            />
          </div>
        </div>

        <div className="col-12">
          <div className="form-group row">
            <label className="col-sm-12 col-form-label">
              CEP <b style={{ color: "red" }}>*</b>
            </label>
            <div className="col-sm-12">
              <input
                className="form-control"
                id="cep"
                placeholder="CEP"
                value={form.cep}
                name="cep"
                type="text"
                onChange={(e) => {
                  handleCepChange(e);
                }}
                disabled={loadingCep}
              />
            </div>
          </div>

          <div className="form-group  row">
            <div className=" col-sm-12">
              <div className="row">
                <div className="col-md-8 col-12">
                  <label className="col-sm-12 col-form-label">
                    Logradouro <b style={{ color: "red" }}>*</b>
                  </label>
                  <div className="col-sm-12">
                    <input
                      className="form-control"
                      id="rua"
                      name="street"
                      placeholder="Logradouro"
                      type="text"
                      onChange={handleInputChange}
                      value={form.street}
                      disabled={loadingCep}
                    />
                  </div>
                </div>

                <div className="col-md-4 col-12">
                  <label className="col-sm-12 col-form-label">Número </label>
                  <div className="col-sm-12">
                    <input
                      className="form-control"
                      id="numero"
                      name="number"
                      placeholder="Número do endereço"
                      type="text"
                      onChange={handleInputChange}
                      value={form.number}
                      disabled={loadingCep}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group  row">
            <div className=" col-sm-12">
              <div className="row">
                <div className="col-md-8 col-12">
                  <label className="col-sm-12 col-form-label">
                    Bairro <b style={{ color: "red" }}>*</b>
                  </label>
                  <div className="col-sm-12">
                    <input
                      className="form-control"
                      placeholder="Nome do bairro"
                      type="text"
                      onChange={handleInputChange}
                      value={form.neighborhood}
                      name="neighborhood"
                      disabled={loadingCep}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <label className="col-sm-12 col-form-label">
                    Complemento
                  </label>
                  <div className="col-sm-12">
                    <input
                      className="form-control"
                      placeholder="Complemento"
                      type="text"
                      onChange={handleInputChange}
                      value={form.complement}
                      name="complement"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group  row">
            <div className=" col-sm-12">
              <div className="row">
                <div className="col-md-10 col-12">
                  <label className="col-sm-12 col-form-label">
                    Localidade <b style={{ color: "red" }}>*</b>
                  </label>
                  <div className="col-sm-12">
                    <input
                      className="form-control"
                      disabled
                      placeholder="Nome da cidade"
                      type="text"
                      onChange={handleInputChange}
                      value={form.city}
                      name="city"
                    />
                  </div>
                </div>
                <div className="col-md-2 col-12">
                  <label className="col-sm-12 col-form-label">
                    Estado<b style={{ color: "red" }}>*</b>
                  </label>
                  <div className="col-sm-12">
                    <input
                      className="form-control"
                      disabled
                      placeholder="UF"
                      type="text"
                      onChange={handleInputChange}
                      value={form.state}
                      name="state"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-12 col-form-label">
            Resíduos <b style={{ color: "red" }}>*</b>
          </label>
          <div className="col-sm-12">
            <select
              value={""}
              className="form-control"
              onChange={(e) => {
                let residuo = wasteRejects.filter(
                  (r) => r.id == e.target.value
                )[0];
                let residuos_selecionados = {
                  ...form.selected_residuos,
                };
                residuos_selecionados[residuo.id] = residuo;

                setForm((prevForm) => ({
                  ...prevForm,
                  selected_residuos: residuos_selecionados,
                }));
              }}
            >
              <option value={""}>
                Selecione os resíduos que serão recebidos
              </option>
              {wasteRejects
                .filter((d) => d.tipo === "Resíduo")
                .map((item, id) => (
                  <option key={id} name={item.nome} value={item.id}>
                    {item.nome}
                  </option>
                ))}
            </select>
          </div>
        </div>
        {Object.values(form.selected_residuos).length !== 0 && (
          <div className="form-group row">
            <label className="col-sm-12 col-form-label">
              Resíduos Selecionados:
            </label>

            {Object.values(form.selected_residuos).map((item, id) => (
              <div
                key={id}
                className="col-sm-6 col-12 col-md-4 col-lg-3"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid gray",
                  borderRadius: 10,
                  marginLeft: 5,
                }}
              >
                <span>{item.nome}</span>
                <button
                  onClick={() => {
                    let residuos_selecionados = {
                      ...form.selected_residuos,
                    };
                    delete residuos_selecionados[item.id];
                    setForm((prevForm) => ({
                      ...prevForm,
                      selected_residuos: residuos_selecionados,
                    }));
                  }}
                  className="btn btn-danger btn-outline btn-circle me-1"
                  style={{
                    background: "white",
                    border: "0px solid red",
                  }}
                >
                  <FaTrash color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="form-group row">
          <label className="col-sm-12 col-form-label">
            Rejeitos <b style={{ color: "red" }}>*</b>
          </label>
          <div className="col-sm-12">
            <select
              value={""}
              className="form-control"
              onChange={(e) => {
                let rejeito = wasteRejects.filter(
                  (r) => r.id == e.target.value
                )[0];
                let rejeitos_selecionados = {
                  ...form.selected_rejeitos,
                };
                rejeitos_selecionados[rejeito.id] = rejeito;

                setForm((prevForm) => ({
                  ...prevForm,
                  selected_rejeitos: rejeitos_selecionados,
                }));
              }}
            >
              <option value={""}>
                Selecione os rejeitos que serão recebidos
              </option>
              {wasteRejects
                .filter((d) => d.tipo === "Rejeito")
                .map((item, id) => (
                  <option key={id} name={item.nome} value={item.id}>
                    {item.nome}
                  </option>
                ))}
            </select>
          </div>
        </div>
        {Object.values(form.selected_rejeitos).length !== 0 && (
          <div className="form-group row">
            <label className="col-sm-12 col-form-label">
              Rejeitos Selecionados:
            </label>

            {Object.values(form.selected_rejeitos).map((item, id) => (
              <div
                key={id}
                className="col-sm-6 col-12 col-md-4 col-lg-3"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid gray",
                  borderRadius: 10,
                  marginLeft: 5,
                }}
              >
                <span>{item.nome}</span>
                <button
                  onClick={() => {
                    let rejeitos_selecionados = {
                      ...form.selected_rejeitos,
                    };
                    delete rejeitos_selecionados[item.id];
                    setForm((prevForm) => ({
                      ...prevForm,
                      selected_rejeitos: rejeitos_selecionados,
                    }));
                  }}
                  className="btn btn-danger btn-outline btn-circle me-1"
                  style={{
                    background: "white",
                    border: "0px solid red",
                  }}
                >
                  <FaTrash color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        {!loadingSave ? (
          <div className="row mt-4">
            <div
              className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
              style={{ marginBottom: "0.5rem" }}
            >
              <Link to="/relacionamentos_internos">
                <span className="btn btn-secondary btn-lg waves-effect waves-light">
                  <MdReply /> Voltar
                </span>
              </Link>
            </div>
            <div
              className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
              style={{ marginBottom: "0.5rem" }}
            >
              <button
                type="button"
                onClick={() => sendInvitation()}
                disabled={loadingSave}
                className="btn btn-success btn-lg waves-effect waves-light"
                style={{ backgorundColor: "#f2f2f2" }}
              >
                Convidar pessoa
              </button>
            </div>
          </div>
        ) : (
          <div className="row">
            <div
              className="col-12 d-flex justify-content-center align-items-center"
              style={{ marginTop: "2rem" }}
            >
              <div className="spinner-border text-primary" role="status"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConvidarOrganizacao;
