import React from 'react'
import { maskCnpj } from '../../../Auxiliar/Masks'

const ComplementoSinir = (props) => {
  return (
    <div className="form-group  row">
      <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Dados Sinir:</label>

      <div className=" col-sm-12">
        <div className="col-md-12 col-12">
          <label className="col-sm-12 col-form-label">CNPJ <b style={{ color: 'red' }}>*</b></label>
          <div className="col-sm-12">
            <input className="form-control" id="numero" placeholder="CNPJ de acesso ao SINIR" type="text" onChange={(e) => props.setState({ cnpjSnir: maskCnpj(e.target.value) })} value={props.state.cnpjSinir} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 col-12">
            <label className="col-sm-12 col-form-label">Unidade<b style={{ color: 'red' }}>*</b></label>
            <div className="col-sm-12">
              <input className="form-control" id="rua" placeholder="Unidade sinir" type="text" onChange={(e) => props.setState({ unidade: e.target.value })} value={props.state.unidade} />
            </div>
          </div>
          <div className="col-md-4 col-12">
            <label className="col-sm-12 col-form-label">Senha <b style={{ color: 'red' }}>*</b></label>
            <div className="col-sm-12">
              <input className="form-control" id="numero" placeholder="Senha de acesso sinir" type="text" onChange={(e) => props.setState({ senha: e.target.value })} value={props.state.senha} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComplementoSinir