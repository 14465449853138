import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { MdSearch, MdVisibility } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import Tooltip from "react-tooltip";

import { logout } from "../../../actions/AppActions";

class RelacionamentosInternos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      path: "/relacionamentos_internos",
      loading_screen: false,
      page: 1,
      total_pages: 1,
      msgErro: "",
      search: "",
      dateOf: "",
      dateFor: "",
      related: [],
      show_warning: false,
      show_erro: false,
      show_delete_success: false,
      relationship_to_delete: "",
    };
  }

  componentDidMount() {

  }

  componentDidUpdate() {
    
  }

  get_relationships(page, search = "", busca = false) {
   return
  }

  render() {
    return (
      <>
        {this.state.redirect == true && <Redirect to={this.state.path} />}
        {this.state.loading_screen == false && (
          <div>
            <div className="row mb-2">
              <div className="col-12 col-md-6">
                <form
                  className="app-search mt-1"
                  style={{ width: "100%" }}
                  onSubmit={(e) => {
                    clearTimeout(this.pesquisarTimeout);
                    this.setState({ page: 1 });
                    this.get_relationships(1, this.state.search, true);
                    e.preventDefault();
                  }}
                >
                  <div
                    className="form-group mb-0"
                    style={{ width: "100%", position: "relative" }}
                  >
                    <input
                      value={this.state.search}
                      name="search"
                      placeholder="Pesquisar pelo nome..."
                      className="form-control"
                      style={{
                        width: "100%",
                        paddingRight: 40,
                        borderRadius: 100,
                      }}
                      onChange={(e) => {
                        console.log(this.props.dateOf);
                        clearTimeout(this.pesquisarTimeout);
                        let search = e.target.value;
                        this.setState({ page: 1, search: search });
                        this.pesquisarTimeout = setTimeout(() => {
                          this.get_relationships(1, search, true);
                        }, 500);
                      }}
                    />
                    <div
                      style={{
                        width: 40,
                        position: "absolute",
                        right: 0,
                        top: 0,
                        bottom: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MdSearch />
                    </div>
                  </div>
                </form>
              </div>

              <div className="col-12 col-md-6 d-flex justify-content-end">
                <Link to="/relacionamentos_internos/criar">
                  <span className="btn btn-primary btn-lg waves-effect waves-light mt-1">
                    Criar Relacionamento
                  </span>
                </Link>
              </div>
            </div>
            <div className="table-rep-plugin">
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <table
                  id="tech-companies-1"
                  className="table table-striped table-hover"
                  style={{ textAlign: "center" }}
                >
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>E-mail</th>
                      <th>Status</th>
                      <th>Tipo usuário</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  {/* dados da lista ----------------------------------- */}

                  <tbody>
                    {this.state.related.map((item, id) => (
                      <tr key={id}>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.status}</td>
                        <td>{item.relation}</td>

                        <td>
                          <Link
                            to={"/relacionamentos_internos/view/" + item.id}
                          >
                            <span
                              className="btn btn-warning btn-outline btn-circle me-1 mb-1"
                              data-tip="Ver"
                            >
                              <Tooltip />
                              <MdVisibility style={{ color: "white" }} />
                            </span>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {this.state.related.length == 0 && (
              <div className="row w-100">
                <div className="col-12">
                  <p style={{ textAlign: "center" }}>
                    Nenhum relacionamento encontrado...
                  </p>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-12 d-flex justify-content-end">
                <ReactPaginate
                  previousLabel={"Anterior"}
                  nextLabel={"Próxima"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.total_pages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={this.handlePageClick.bind(this)}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  initialPage={this.state.page - 1}
                />
              </div>
            </div>
          </div>
        )}

        {
          //loading
          this.state.loading_screen == true && (
            <div className="row">
              {" "}
              <div
                className="col-12 d-flex justify-content-center align-items-center"
                style={{ marginTop: "2rem" }}
              >
                <div
                  className="spinner-border text-primary"
                  role="status"
                ></div>
              </div>
            </div>
          )
        }
        <SweetAlert
          warning
          title={"Atenção"}
          onConfirm={() => {
            this.delete_relationship(this.state.relationship_to_delete);
          }}
          onCancel={() => {
            this.setState({ show_warning: false });
          }}
          show={this.state.show_warning}
          confirmBtnText="Sim, desejo apagar"
          cancelBtnText="Cancelar"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          showCancel={true}
        >
          Deseja mesmo deletar esse relacionamento? Essa ação é irreversível.
        </SweetAlert>
        <SweetAlert
          danger
          title={"Erro"}
          onConfirm={() => {
            this.setState({ show_erro: false });
          }}
          show={this.state.show_erro}
          confirmBtnText="OK"
          confirmBtnBsStyle="primary"
        >
          {this.state.msgErro}
        </SweetAlert>
        <SweetAlert
          success
          title={"Sucesso"}
          onConfirm={() => {
            this.setState({ show_delete_success: false });
          }}
          show={this.state.show_delete_success}
          confirmBtnText="OK"
          confirmBtnBsStyle="primary"
        >
          O relacionamento foi excluído com sucesso!
        </SweetAlert>
      </>
    );
  }
  handlePageClick(page) {
    if (page.selected + 1 != this.state.page) {
      this.setState({ page: page + 1 });
      this.get_relationships(page.selected + 1);
    }
  }
}

const mapsStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapsStateToProps, { logout })(RelacionamentosInternos);
