import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import { URL } from '../../../variables';
import { MdReply } from 'react-icons/md';
import { maskNumberBr } from '../../../Auxiliar/Masks';

class ViewDestino extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      path: '/destinos',
      loading_screen: false,
      destinos: [],
      materiais: [],
      residuos_selecionados: {},
      rejeitos_selecionados: {},
      pessoas_selecionadas: {},
      pessoas: {},
      pessoa: '',
      residuos: {},
      rejeitos: '',
      nomedoc: '',
      link_pdf: '',
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      estado: '',
      cidade: '',
      contato: '',
      cnpj: '',
      razao: '',
      fantasia: '',
      descricao: '',
      licenca: '',
      numlicenca: '',
      doclicenca: '',
      tipolicenca: '',
      loading_save: false,
      msgErro: '',
      sigor: false,
      unidade_sigor: '',
      seedTransportadora: false
    };
  }
  componentDidMount() {

    this.get_destino(this.props.match.params.id);
  }

  get_destino(id) {
    if (this.state.loading_screen == false) {
      this.setState({ loading_screen: true });
    }
    fetch(`${URL}api/get_destino/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${this.props.token}`,
      }
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        console.log(resp);
        if (resp.message == "Unauthenticated.") {
          localStorage.removeItem('token'); localStorage.removeItem('user');
          // this.setState({redirect:true,path:'/'});
          this.props.logout();
          return;
        }
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.log(erro);
          this.setState({ redirect: true });
        }
        else {
          if (!resp.destino.is_open) {
            this.setState({ redirect: true })
            return
          };

          let residuos_selecionados = [];
          for (let i = 0; i < resp.destino.residuos.length; i++) {
            residuos_selecionados[resp.destino.residuos[i].id] = resp.destino.residuos[i];
          }

          let rejeitos_selecionados = [];
          for (let i = 0; i < resp.destino.rejeitos.length; i++) {
            rejeitos_selecionados[resp.destino.rejeitos[i].id] = resp.destino.rejeitos[i];
          }

          // Unificar os arrays de residuos e rejeitos
          let todosMateriais = resp.destino.residuos.concat(resp.destino.rejeitos);

          // Criar um novo array com os atributos desejados
          let materiais_selecionados = todosMateriais.map(material => ({
            id: material.id,
            nome: material.nome,
            tipo: material.tipo,
            valor_saldo_financeiro: material.valor_saldo_financeiro,
            saldo_financeiro: material.saldo_financeiro
          }));

          this.setState({
            numero: resp.destino.numero == null ? 'S/N' : resp.destino.numero,
            descricao: resp.destino.descricao == null ? '' : resp.destino.descricao,
            complemento: resp.destino.complemento == null ? '' : resp.destino.complemento,
            loading_screen: false,
            link_pdf: resp.destino.DocLicenca,
            cep: resp.destino.cep,
            logradouro: resp.destino.logradouro,
            bairro: resp.destino.bairro,
            estado: resp.destino.estado,
            cidade: resp.destino.cidade,
            contato: resp.destino.user_id,
            cnpj: resp.destino.cnpj,
            razao: resp.destino.razao,
            fantasia: resp.destino.fantasia,
            licenca: resp.destino.licenca,
            numlicenca: resp.destino.NumLicenca == null ? '' : resp.destino.NumLicenca,
            tipolicenca: resp.destino.TipoLicenca == null ? '' : resp.destino.TipoLicenca,
            residuos_selecionados: residuos_selecionados,
            rejeitos_selecionados: rejeitos_selecionados,
            materiais: materiais_selecionados,
            sigor: resp.destino.unidade_sigor ? 'Sim,' : 'Não',
            unidade_sigor: resp.destino.unidade_sigor ? resp.destino.unidade_sigor.num : '',
            seedTransportadora: resp.has_transport,
            pessoas_selecionadas: {
              ...resp.destino.pessoas_selecionadas.reduce((acc, item) => {
                return {
                  ...acc,
                  [item.id]: { ...item, name: item.user.name, id: item.user.id }
                };
              }, {})
            }
          });

        }
      } catch (err) {
        this.setState({ redirect: true });
        console.log(err);
      }
    })
      .catch((err) => {
        this.setState({ redirect: true });
        console.log(err);
      })
  }


  render() {
    return (
      <div className="row">
        {this.state.redirect == true && <Redirect to={this.state.path} />}
        <div className="col-12">
          <div className="card">
            <div className="card-body" id="card">
              <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Ver destino</h4>
              <p style={{ fontSize: '1rem', color: 'black' }}>Veja um destino do seu sistema. Este também possui uma conta na Blueen Open, por isso não pode ser editado.</p>
              <br />

              {this.state.loading_screen != true && <div className="row">
                <div className="col-12">
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label" style={{ fontSize: "17px" }}>CNPJ: <span style={{ fontWeight: 'normal' }}>{this.state.cnpj}</span></label>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label" style={{ fontSize: "17px" }}>Razão Social: <span style={{ fontWeight: 'normal' }}>{this.state.razao}</span></label>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label" style={{ fontSize: "17px" }}>Nome Fantasia: <span style={{ fontWeight: 'normal' }}>{this.state.fantasia}</span></label>
                  </div>
                  <h4 className="mt-4 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Localização</h4>
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label" style={{ fontSize: "17px" }}>CEP: <span style={{ fontWeight: 'normal' }}>{this.state.cep}</span></label>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label" style={{ fontSize: "17px" }}>Endereço: <span style={{ fontWeight: 'normal' }}>{this.state.logradouro} - {this.state.numero}, {this.state.bairro}. {this.state?.complemento}</span></label>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label" style={{ fontSize: "17px" }}>Cidade: <span style={{ fontWeight: 'normal' }}>{this.state.cidade} - {this.state.estado}</span></label>
                  </div>
                  <h4 className="mt-4 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Pessoas</h4>
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label" style={{ fontSize: "17px" }}>Responsável: <span style={{ fontWeight: 'normal' }}>{this.state.contato.name} - {this.state.contato.email}</span></label>
                  </div>
                  {Object.values(this.state.pessoas_selecionadas).length != 0 && <div className="form-group row">
                    <label className="col-sm-12 col-form-label">Pessoas autorizadas selecionadas:</label>
                    {Object.values(this.state.pessoas_selecionadas).map((item, id) => (
                      <div key={id} className="col-sm-4  col-md-4 col-lg-3" style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        border: '1px solid gray',
                        borderRadius: 10,
                        marginLeft: 10,
                        marginTop: 10

                      }}>
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>}
                  <h4 className="mt-4 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Detalhes</h4>
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label" style={{ fontSize: "17px" }}>Possui registro no sigor: <span style={{ fontWeight: 'normal' }}>{this.state.sigor} {this.state.unidade_sigor}</span></label>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">Materias Selecionadas:</label>
                    <table id="tech-companies-1" className="table table-striped table-hover" >
                      <thead>
                        <tr style={{ textAlign: 'center' }}>
                          <th>Nome</th>
                          <th>Categoria</th>
                          <th>Movimentação Financeira</th>
                          <th>Saldo Financeiro Estimado</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.materiais.map((item, id) => (
                          <tr key={id} style={{ textAlign: 'center' }}>
                            <td>{item.nome}</td>
                            <td>{item.tipo}</td>
                            <td>{item.saldo_financeiro}</td>
                            <td>R$ {maskNumberBr(item.valor_saldo_financeiro)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                    style={{ marginBottom: '0.5rem' }}>
                    <Link to="/destinos"> <span
                      className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                  </div>
                </div>
              </div>}

              {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                <div className="spinner-border text-primary" role="status"  >
                </div>
              </div></div>}
            </div>

          </div>
        </div>
      </div>
    );
  }
}
const mapsStateToProps = (state) => (
  {
    token: state.AppReducer.token,
    permissions: state.AppReducer.permissoes,
  }
);

export default connect(mapsStateToProps, { logout })(ViewDestino);