import React from 'react'
import { MdReply } from 'react-icons/md'
import Label from '../../../Auxiliar/Label'
import { GET } from '../../../Auxiliar/Requests'
import { StatusColeta } from '../../../Configs/Status'
import FinishDestinationModal from './FinishDestinationModal'
import StatusStepper from '../../../Auxiliar/StatusStepper'
import { maskDate, maskNumberBr, moneyMask, moneyMask2, numberLabelMask } from '../../../Auxiliar/Masks'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Chip } from '@mui/material'
import { URL } from '../../../variables'

const ViewDetails = () => {
  const history = useHistory()
  const { processType, id } = useParams()

  const [data, setData] = React.useState('')
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    getData()
  }, [])

  //-------------------------*-------------------------
  const getData = async () => {
    const response = await GET({ url: `${processType}/view/${id}` })

    if (!response) {
      console.log('error')
      history.push('/acompanhar_etapas')
      return
    }

    setData(response.data)
    setLoading(false)
  }

  return (
    <div className="card">
      <div className="card-body" id="card">
        {!loading && data && <>
          <h1 className="mb-4 header-title" style={{ fontSize: '1.5rem' }}>Detalhes da Solicitação</h1>
          <Label title='Código' text={data.code} />
          <Label title='Status' text={data.status} />
          <div className="my-3" style={{overflowY:'scroll'}}>
            <StatusStepper statusIndex={StatusColeta.getKeyByValue(data.status)} />
          </div>
          <Label title={data.stock ? 'Estoque' : 'Destino'} text={data.stock ?? data.destiny} />
          <hr className='my-5' />
          <div className="mt-4">
            <h2 className="header-title" style={{ fontSize: '1.2rem', color: 'black' }}>Dados das solicitações em aberto</h2>
            {data.collect_requests.map(item => (
              <div className='mb-5' key={item.id}>
                <Label title='Solicitação de coleta' text={item.code} />
                <Label title='Ponto de coleta' text={item.collect_point} />
                <Label title='Responsável' text={item.responsible} />
                <Label title='Materiais selecionados' text='' />
                <table className="table table-striped table-hover"  >
                  <thead>
                    <tr>
                      <th>Material</th>
                      <th>Peso</th>
                      <th>Volume</th>
                      <th>Peso Solicitado</th>
                      <th>Volume Solicitado</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.request_materials.map(material => (
                      <tr key={item.id + material.id}>
                        <td>{material.name}</td>
                        <td>{material.weight}</td>
                        <td>{material.volume}</td>
                        <td>{material.requested_weight}</td>
                        <td>{material.requested_volume}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
          <hr className='my-5' />
          <div className="mt-4">
            <h2 className=" header-title" style={{ fontSize: '1.2rem', color: 'black' }}>Dados do despacho</h2>
            <div className="d-flex align-items-center">
              <Label title='Situação' text='' spaced={false} />
              <Chip
                label={data.status}
                color={StatusColeta.getPallete(data.status)}
                size="small"
                className='ms-2'
              />
            </div>
            <Label title='Data de criação' text={maskDate(data.created_at)} />
            <Label title='Agendado Para' text={data.scheduled_to ? maskDate(data.scheduled_to) : 'Não marcado'} />
            <Label title='Iniciado em' text={data.started_at ? maskDate(data.started_at) : 'Não iniciado'} />
            <Label title='Finalizado em' text={data.finished_at ? maskDate(data.finished_at) : 'Não finalizado'} />
            <Label title='Peso total' text={numberLabelMask(data.total_weight)} />
            <Label title='Volume total' text={numberLabelMask(data.total_volume)} />
            <Label title='Receita' text={moneyMask2(data.total_receipt)} />
            <Label title='Despesa' text={moneyMask2(data.total_expense)} />
            <Label title='Saldo' text={moneyMask2(data.balance)} />
            <Label title='Materiais selecionados' text='' />
            <table className="table table-striped table-hover"  >
              <thead>
                <tr>
                  <th>Material</th>
                  <th>Peso</th>
                  <th>Volume</th>
                  <th>Ponto Coleta</th>
                </tr>
              </thead>
              <tbody>
                {data.materials.map(item => (
                  <tr key={item.collect_point + item.id}>
                    <td>{item.name}</td>
                    <td>{numberLabelMask(item.weight)}</td>
                    <td>{numberLabelMask(item.volume)}</td>
                    <td>{item.collect_point}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <hr className='my-5' />
          <div className="mt-4">
            <h2 className=" header-title" style={{ fontSize: '1.2rem', color: 'black' }}>Dados do transporte</h2>
            <Label title='Transportadora' text={data.carrier} />
            <Label title='Veículos/Motoristas selecionados' text='' />
            <table className="table table-striped table-hover" >
              <thead>
                <tr>
                  <th>Veículo</th>
                  <th>Motorista</th>
                  <th>Capacidade Peso</th>
                  <th>Capacidade Volume</th>
                </tr>
              </thead>
              <tbody>
                {!data.vehicles.length ?
                  <tr>
                    <td colSpan="4" style={{ textAlign: 'center' }}>Nenhum Veículo/Motorista registrado</td>
                  </tr>
                  :
                  data.vehicles.map(item => (
                    <tr key={item.id}>
                      <td>{item.vehicle}</td>
                      <td>{item.driver}</td>
                      <td>{numberLabelMask(item.weight_capacity)}</td>
                      <td>{numberLabelMask(item.volume_capacity)}</td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <Label title='Peso total Veículo(s)' text={numberLabelMask(data.total_weight_capacity)} />
            <Label title='Volume total Veículo(s)' text={numberLabelMask(data.total_volume_capacity)} />
            <Label title='Observações' text={data.description ?? 'Sem observações'} />
          </div>
          <hr className='my-5' />
          <h2 className="header-title" style={{ fontSize: '1.2rem', color: 'black' }}>Informações fiscais</h2>
          {data.documents.nf.key ?
            <>
              <div className="d-flex">
                <Label title='Chave nota' text={data.documents.nf.key} spaced={false} />
                <Label title='Número nota' text={data.documents.nf.number} className='ms-2' spaced={false} />
                <Label title='Série nota' text={data.documents.nf.serie} className='ms-2' spaced={false} />
              </div>
              {data.documents.nf.document &&
                <div className="row">
                  <iframe style={{ height: 1000 }} src={URL + 'storage/' + data.documents.nf.document} frameborder="0" />
                </div>}
            </> :
            <Label title='Nota físcal' text='Sem registros' />}

          <Label title='MTR' text={data.documents.mtr ?? 'Sem registros'} />
          <Label title='CRD' text={data.documents.crd ?? 'Sem registros'} />

          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-secondary btn-lg waves-effect waves-light"
              onClick={() => history.goBack()}>
              <MdReply /> Voltar
            </button>

            {(processType === 'destinacao' && !data.finished_at && data.status === 'Iniciado') && <FinishDestinationModal data={data} />}
          </div>
        </>}

        {loading &&
          <div className="col-12 d-flex justify-content-center align-items-center py-5">
            <div className="spinner-border text-primary" role="status"></div>
          </div>}
      </div>
    </div>
  )
}

export default ViewDetails