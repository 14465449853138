import React from 'react'
import Filter from '../../../Auxiliar/Filter'
import CardList from './CardList'

const filterOptions = {
  dispatches: { value: true, label: 'Despachos', checked: true },
  destinations: { value: false, label: 'Destinações', checked: false }
}

const Kanban = () => {
  const [reload, setReload] = React.useState(true)
  const [dateOf, setDateOf] = React.useState('')
  const [dateFor, setDateFor] = React.useState('')
  const [options, setOptions] = React.useState(filterOptions)
  const [finishedAlert, setFinishedAlert] = React.useState(true)

  React.useEffect(() => {
    if (reload) {
      setFinishedAlert(!dateFor || !dateOf)
    }
  }, [reload])

  return (
    <div className="card">
      <div className="card-body" id="card">
        <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Kanban</h4>
        <Filter setDateOf={setDateOf} setDateFor={setDateFor} options={options} setOptions={setOptions} setSearch={setReload} />
        <p className='m-0' style={{ fontSize: '1rem', color: 'black' }}>Acompanhe e execute ações em todas as solicitações em andamento</p>
        {finishedAlert &&
          <p style={{ fontSize: '0.9rem', color: 'black', fontWeight: 600 }}>
            Quando não possuir nenhum filtro ativo, a busca será restrita aos registros finalizados apenas do mês atual
          </p>}
        <div className="row">
          <CardList {...{ dateOf, dateFor, options, reload }} />
        </div>
      </div>
    </div>
  )
}

export default Kanban