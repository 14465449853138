import React, { Component } from "react";
import { logout } from "../../../actions/AppActions";
import { connect } from "react-redux";

// users
import moment from "moment";
import { NAME, URL } from "../../../variables";
import ReactPaginate from "react-paginate";
import { MdReply } from "react-icons/md";
import { maskNumber, maskCep, maskCnpj } from "../../../Auxiliar/Masks";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ComplementoTransportadora from "./ComplementoTransportadora";
import { CircularProgress } from "@mui/material";
import ComplementoSigor from "./ComplementoSigor";
import ComplementoSinir from "./ComplementoSinir";

class EditarEmpresa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      loading_screen: false,
      loading_extra: 0,
      senha: "",
      unidade: "",
      cnpj: "",
      cnpjSigor: "",
      cnpjSinir: "",
      razao_social: "",
      nome_fantasia: "",
      desc: "",
      numero: "",
      cep: "",
      bairro: "",
      cidade: "",
      estado: "",
      logradouro: "",
      complemento: "",
      loading_save: false,
      msgErro: "",
      msgSuccess: "",
      checkEstoque: false,
      checkTranportadora: false,
      lastSelect: "",
      defaultKey: "",
      defaultKeySinir: "",
      screen_transportadora: false,
      transportadora_id: "",
      responsavel_id: "",
      licenca: "",
      descricao: "",
    };
  }

  salvar() {
    this.setState({ loading_save: true, msgErro: "", msgSuccess: "" });
    fetch(`${URL}api/store_empresa`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cnpj: this.state.cnpj,
        razao_social: this.state.razao_social,
        nome_fantasia: this.state.nome_fantasia,
        numero: this.state.numero,
        cep: this.state.cep,
        bairro: this.state.bairro,
        cidade: this.state.cidade,
        estado: this.state.estado,
        logradouro: this.state.logradouro,
        complemento: this.state.complemento,
        desc: this.state.desc,
        seed_estoque: this.state.checkEstoque,

        transportadora_id: this.state.transportadora_id,
        status_transportadora: this.state.checkTranportadora,
        responsavel_id_transportadora: this.state.responsavel_id_transportadora,
        licenca: this.state.licenca,
        descricao: this.state.descricao,

        eSigor: this.state.eSigor,
        eSinir: this.state.eSinir,
        unidade: this.state.unidade,
        cnpjSinir: this.state.cnpjSinir.replace(/[^0-9]/g, ""),
        cnpjSigor: this.state.cnpjSigor.replace(/[^0-9]/g, ""),
        senha: this.state.senha,

        connection: NAME,
      }),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            console.log(erro);
            window.scrollTo(500, 0);

            this.setState({ loading_save: false, msgErro: erro });
          } else {
            window.scrollTo(500, 0);

            this.setState({
              loading_save: false,
              redirect: true,
              msgSuccess: "Dados da sua Empresa editado com sucesso",
            });
          }
        } catch (err) {
          console.log(err);
          window.scrollTo(500, 0);

          this.setState({
            loading_save: false,
            msgErro: "Erro ao pegar resposta do servidor",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        window.scrollTo(500, 0);

        this.setState({
          loading_save: false,
          msgErro:
            "Erro ao pegar resposta do servidor. Você está conectado a internet?",
        });
      });
  }

  componentDidMount() {
    this.get_empresa();
  }

  get_empresa() {
    if (this.state.loading_screen == false) {
      this.setState({ loading_screen: true });
    }
    fetch(`${URL}api/get_empresa/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${this.props.token}`,
        // 'Content-Type': 'application/json',
      },
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          console.log(resp);
          if (resp.message == "Unauthenticated.") {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            // this.setState({redirect:true,path:'/'});
            this.props.logout();
            return;
          }
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            console.log(erro);
            this.setState({ redirect: true });

            //   setTimeout(()=>{
            //     this.get_processo();
            //   });
          } else {
            // this.props.mudarUser(resp.user);
            console.log("resp", resp);
            // let cpfCnpj = ''
            // cpfCnpj = Array.from(resp.empresa.sigor_gerador.cnpjSigor)

            this.setState({
              loading_screen: false,
              loading_extra: 20,
              cnpj: resp.empresa.cnpj == null ? "" : resp.empresa.cnpj,
              razao_social:
                resp.empresa.razao_social == null
                  ? ""
                  : resp.empresa.razao_social,
              nome_fantasia:
                resp.empresa.nome_fantasia == null
                  ? ""
                  : resp.empresa.nome_fantasia,
              desc: resp.empresa.desc == null ? "" : resp.empresa.desc,
              numero: resp.empresa.numero == null ? "" : resp.empresa.numero,
              cep: resp.empresa.cep == null ? "" : resp.empresa.cep,
              bairro: resp.empresa.bairro == null ? "" : resp.empresa.bairro,
              cidade: resp.empresa.cidade == null ? "" : resp.empresa.cidade,
              estado: resp.empresa.estado == null ? "" : resp.empresa.estado,
              logradouro:
                resp.empresa.logradouro == null ? "" : resp.empresa.logradouro,
              complemento:
                resp.empresa.complemento == null
                  ? ""
                  : resp.empresa.complemento,
              checkEstoque: !resp.empresa.seed_estoque
                ? false
                : resp.empresa.seed_estoque,
              eSinir: true ? false : true,
              eSigor: !resp.empresa.eSigor ? false : true,
              defaultKey: resp.empresa.eSigor ? "Sigor" : "Transportadora",
              defaultKeySinir: resp.empresa.eSinir ? "Sinir" : "Transportadora",
              ...resp.empresa.sigor_gerador,
              ...resp.empresa.sinir_gerador,
              cnpjSigor: resp.empresa.sigor_gerador
                ? maskCnpj(resp.empresa.sigor_gerador.cnpjSigor)
                : "",
              cnpjSinir: resp.empresa.sinir_gerador
                ? maskCnpj(resp.empresa.sinir_gerador.cnpjSinir)
                : "",
            });
            this.loadTransportadora();
            // if (resp.processo != null) {
          }
        } catch (err) {
          this.setState({ redirect: true });
          console.log(err);
        }
      })
      .catch((err) => {
        this.setState({ redirect: true });

        console.log(err);
        // this.props.mudarLoadingHome(false);
      });
  }

  buscar_endereco(cep) {
    this.setState({ loading_cep: true });
    console.log(cep);
    fetch(`https://viacep.com.br/ws/${cep}/json/`, {
      method: "GET",
      headers: {},
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          console.log(resp);
          this.setState(
            {
              logradouro: resp.logradouro,
              bairro: resp.bairro,
              cidade: resp.localidade,
              estado: resp.uf,
              numero: "",
              loading_cep: false,
            },
            () => {
              if (this.state.logradouro == "") {
                document.getElementById("rua").focus();
              } else {
                document.getElementById("numero").focus();
              }
            }
          );

          // await AsyncStorage.setItem('token', resp.access_token)
          // await AsyncStorage.setItem('user', JSON.stringify(resp.user))
        } catch (err) {
          console.log(err);
          this.setState({
            loading: false,
            msgErro: "Algo deu errado. Seu CEP está correto?",
            loading_cep: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          msgErro: `Erro ao pegar resposta do servidor. Você está conectado a internet?`,
          loading_cep: false,
        });
      });
  }

  change_tipo(event) {
    this.setState({ tipo: event.target.value });
  }

  loadTransportadora() {
    if (this.state.cnpj) {
      this.setState({ loading_extra: this.state.loading_extra + 30 });
      fetch(`${URL}api/get_own_transportadora/${this.state.cnpj}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.token}`,
          // 'Content-Type': 'application/json',
        },
      })
        .then(async (responseLog) => {
          let resp = await responseLog.json();
          this.setState({ loading_extra: this.state.loading_extra + 30 });
          return resp;
        })
        .then((resp) => {
          let tr = resp.transportadora;
          if (resp.status) {
            this.setState({
              checkTranportadora: true,
              defaultKey: "Transportadora",
              responsavel_id_transportadora: tr.responsavel_id,
              licenca: tr.licenca,
              descricao: tr.descricao,
              loading_screen_transportadora: false,
              transportadora_id: tr.id,
              loading_extra: this.state.loading_extra + 40,
            });
          } else {
            this.setState({
              loading_screen_transportadora: false,
              loading_extra: this.state.loading_extra + 40,
            });
          }
        });
    } else {
      this.setState({
        loading_screen_transportadora: false,
        loading_extra: this.state.loading_extra + 100,
      });
    }
  }

  handleCheckboxChange = async (checkboxId) => {
    this.setState((prevState) => ({
      ...prevState,
      [checkboxId]: !prevState[checkboxId],
    }));
  
    try {
      const response = await fetch(`${URL}api/atualizarCheckbox`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          checkboxId,
          isChecked: !this.state[checkboxId],
        }),
      });
  
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Erro ao enviar a solicitação ao back-end", error);
    }
  };

  render() {
    let anos = [];
    for (let i = parseInt(moment().format("YYYY")); i >= 1990; i--) {
      anos.push(i);
    }
    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body" id="card">
              <h4
                className="mt-0 header-title"
                style={{ fontSize: "1.5rem", color: "black" }}
              >
                Editar empresa
              </h4>
              <p style={{ fontSize: "1rem", color: "black" }}>
                Edite uma empresa do seu sistema
              </p>
              <br />

              {/* <hr /> */}
              {this.state.loading_screen == false && (
                <div>
                  {this.state.msgErro != "" && (
                    <div>
                      <div
                        className="alert alert-danger alert-dismissible fade show mb-0"
                        role="alert"
                      >
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                          onClick={() => this.setState({ msgErro: "" })}
                        ></button>

                        <p style={{ marginBottom: 0, whiteSpace: "pre" }}>
                          {this.state.msgErro}
                        </p>
                      </div>
                      <br />
                    </div>
                  )}
                  {this.state.msgSuccess != "" && (
                    <div>
                      <div
                        className="alert alert-success alert-dismissible fade show mb-0"
                        role="alert"
                      >
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                          onClick={() => this.setState({ msgSuccess: "" })}
                        ></button>

                        <p style={{ marginBottom: 0, whiteSpace: "pre" }}>
                          {this.state.msgSuccess}
                        </p>
                      </div>
                      <br />
                    </div>
                  )}
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">
                          CNPJ <b style={{ color: "red" }}>*</b>
                        </label>
                        <div className="col-sm-12">
                          <input
                            className="form-control"
                            placeholder="CNPJ"
                            type="text"
                            onChange={(e) =>
                              this.setState({ cnpj: maskCnpj(e.target.value) })
                            }
                            value={this.state.cnpj}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">
                          Razão social <b style={{ color: "red" }}>*</b>
                        </label>
                        <div className="col-sm-12">
                          <input
                            className="form-control"
                            placeholder="Razão social"
                            type="text"
                            onChange={(e) =>
                              this.setState({ razao_social: e.target.value })
                            }
                            value={this.state.razao_social}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">
                          Nome fantasia <b style={{ color: "red" }}>*</b>
                        </label>
                        <div className="col-sm-12">
                          <input
                            className="form-control"
                            placeholder="Nome fantasia"
                            type="text"
                            onChange={(e) =>
                              this.setState({ nome_fantasia: e.target.value })
                            }
                            value={this.state.nome_fantasia}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">
                          Descrição{" "}
                        </label>
                        <div className="col-sm-12">
                          <textarea
                            className="form-control"
                            placeholder="Descrição"
                            type="text"
                            onChange={(e) =>
                              this.setState({ desc: e.target.value })
                            }
                            value={this.state.desc}
                          ></textarea>
                        </div>
                      </div>

                      <hr />
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">
                          CEP <b style={{ color: "red" }}>*</b>
                        </label>
                        <div className="col-sm-12">
                          <input
                            className="form-control"
                            placeholder="CEP"
                            value={this.state.cep}
                            type="text"
                            onChange={(e) => {
                              this.setState({ cep: maskCep(e.target.value) });
                              let text = e.target.value.replace("-", "");
                              if (text.length == 8) {
                                this.buscar_endereco(text);
                              }
                            }}
                          />
                        </div>
                      </div>

                      <div className="form-group  row">
                        <div className=" col-sm-12">
                          <div className="row">
                            <div className="col-md-8 col-12">
                              <label className="col-sm-12 col-form-label">
                                Logradouro <b style={{ color: "red" }}>*</b>
                              </label>
                              <div className="col-sm-12">
                                <input
                                  className="form-control"
                                  id="rua"
                                  placeholder="Logradouro do endereço"
                                  type="text"
                                  onChange={(e) =>
                                    this.setState({
                                      logradouro: e.target.value,
                                    })
                                  }
                                  value={this.state.logradouro}
                                />
                              </div>
                            </div>
                            <div className="col-md-3 col-12">
                              <label className="col-sm-12 col-form-label">
                                Número <b style={{ color: "red" }}>*</b>
                              </label>
                              <div className="col-sm-12">
                                <input
                                  className="form-control"
                                  id="numero"
                                  placeholder="Número do endereço"
                                  type="text"
                                  onChange={(e) =>
                                    this.setState({ numero: e.target.value })
                                  }
                                  value={this.state.numero}
                                />
                              </div>
                            </div>
                            <div className="col-md-1 col-12">
                              <label
                                className="col-form-label col-sm-12"
                                htmlFor="select-all"
                              >
                                S/N
                              </label>
                              <div className="form-check form-switch col-sm-12 ">
                                <input
                                  className="form-check-input"
                                  checked={
                                    this.state.numero == "S/N" ? true : false
                                  }
                                  type="checkbox"
                                  id={"select-all"}
                                  onChange={(e) => {
                                    this.setState({
                                      numero: e.target.checked ? "S/N" : "",
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group  row">
                        <div className=" col-sm-12">
                          <div className="row">
                            <div className="col-md-8 col-12">
                              <label className="col-sm-12 col-form-label">
                                Bairro <b style={{ color: "red" }}>*</b>
                              </label>
                              <div className="col-sm-12">
                                <input
                                  className="form-control"
                                  placeholder="Nome do bairro"
                                  type="text"
                                  onChange={(e) =>
                                    this.setState({ bairro: e.target.value })
                                  }
                                  value={this.state.bairro}
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                              <label className="col-sm-12 col-form-label">
                                Complemento
                              </label>
                              <div className="col-sm-12">
                                <input
                                  className="form-control"
                                  placeholder="Complemento"
                                  type="text"
                                  onChange={(e) =>
                                    this.setState({
                                      complemento: e.target.value,
                                    })
                                  }
                                  value={this.state.complemento}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group  row">
                        <div className=" col-sm-12">
                          <div className="row">
                            <div className="col-md-10 col-12">
                              <label className="col-sm-12 col-form-label">
                                Cidade <b style={{ color: "red" }}>*</b>
                              </label>
                              <div className="col-sm-12">
                                <input
                                  className="form-control"
                                  disabled
                                  placeholder="Nome da cidade"
                                  type="text"
                                  onChange={(e) =>
                                    this.setState({ cidade: e.target.value })
                                  }
                                  value={this.state.cidade}
                                />
                              </div>
                            </div>
                            <div className="col-md-2 col-12">
                              <label className="col-sm-12 col-form-label">
                                Estado<b style={{ color: "red" }}>*</b>
                              </label>
                              <div className="col-sm-12">
                                <input
                                  className="form-control"
                                  disabled
                                  placeholder="UF"
                                  type="text"
                                  onChange={(e) =>
                                    this.setState({ estado: e.target.value })
                                  }
                                  value={this.state.estado}
                                />
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="d-flex mt-5">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                checked={this.state.checkTranportadora}
                                onChange={() =>
                                  this.setState({
                                    checkTranportadora:
                                      !this.state.checkTranportadora,
                                    defaultKey: "Transportadora",
                                  })
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexSwitchCheckDefault"
                              >
                                Utilizar empresa como transportadora
                              </label>
                            </div>
                            {this.props.permissoes.sigor?.acesso && (
                              <div className="form-check form-switch ms-5">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  checked={this.state.eSigor}
                                  onChange={() =>
                                    this.setState({
                                      eSigor: !this.state.eSigor,
                                      defaultKey: "Sigor",
                                    })
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexSwitchCheckDefault"
                                >
                                  Ativar Emissão SIGOR
                                </label>
                              </div>
                            )}
                            
                              <div className="form-check form-switch ms-5">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  checked={this.state.eSinir}
                                  onChange={() =>
                                    this.setState({
                                      eSinir: !this.state.eSinir,
                                      defaultKey: "Sinir",
                                    })
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexSwitchCheckDefault"
                                >
                                  Ativar Emissão SINIR
                                </label>
                              </div>
                            

                            <div className="form-check form-switch ms-5">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                checked={this.state.checkEstoque}
                                onChange={() =>
                                  this.setState({
                                    checkEstoque: !this.state.checkEstoque,
                                  })
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexSwitchCheckDefault"
                              >
                                Utilizar dados no cadastro de estoque
                              </label>
                            </div>
                          </div>
                          {this.state.loading_extra >= 100 && (
                            <Tabs
                              transition={false}
                              activeKey={this.state.defaultKey}
                              onSelect={(e) => this.setState({ defaultKey: e })}
                              id="uncontrolled-tab-example"
                            >
                              {this.state.checkTranportadora && (
                                <Tab
                                  eventKey="Transportadora"
                                  title="Transportadora"
                                >
                                  <ComplementoTransportadora
                                    form={this.state}
                                    setForm={(e) => this.setState(e)}
                                  />
                                </Tab>
                              )}
                              {this.state.eSigor &&
                                this.props.permissoes.sigor?.acesso && (
                                  <Tab eventKey="Sigor" title="Sigor">
                                    <ComplementoSigor
                                      state={this.state}
                                      setState={(e) => this.setState(e)}
                                    />
                                  </Tab>
                                )}
                              {this.state.eSinir &&
                               (
                                  <Tab eventKey="Sinir" title="Sinir">
                                    <ComplementoSinir
                                      state={this.state}
                                      setState={(e) => this.setState(e)}
                                    />
                                  </Tab>
                                )}
                            </Tabs>
                          )}

                          {this.state.loading_extra < 100 && (
                            <div className="d-flex justify-content-center p-5">
                              <CircularProgress
                                className="m-auto"
                                color="success"
                                variant="determinate"
                                value={this.state.loading_extra}
                                size={40}
                              />
                            </div>
                          )}
                          {this.state.loading_extra > 100 &&
                            this.state.checkTranportadora &&
                            !this.state.screen_transportadora && (
                              <div className="d-flex justify-content-center p-5">
                                <CircularProgress
                                  className="m-auto"
                                  color="success"
                                  variant="indeterminate"
                                  size={40}
                                />
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}
                  </div>
                  <div className="row mt-3">
                    <div className="col-12">
                      <div
                        className="mt-0 header-title"
                        style={{ fontSize: "1.5rem", color: "black" }}
                      >
                        Perfil
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="checkbox1"
                          onChange={() => this.handleCheckboxChange('checkbox1')}
                        />
                        <label className="form-check-label" htmlFor="checkbox1">
                          Gerador
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="checkbox2"
                          onChange={() => this.handleCheckboxChange('checkbox2')}
                          
                        />
                        <label className="form-check-label" htmlFor="checkbox2">
                          Transportador
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="checkbox3"
                          onChange={() => this.handleCheckboxChange('checkbox3')}
                          
                        />
                        <label className="form-check-label" htmlFor="checkbox3">
                          Armazenador Temporário
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="checkbox4"
                          onChange={() => this.handleCheckboxChange('checkbox4')}
                        />
                        <label className="form-check-label" htmlFor="checkbox4">
                          Destinador
                        </label>
                      </div>
                    </div>
                  </div>

                  {this.state.loading_save == false && (
                    <div className="row mt-3">
                      <div
                        className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                        style={{ marginBottom: "0.5rem" }}
                      ></div>
                      <div
                        className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                        style={{ marginBottom: "0.5rem" }}
                      >
                        <button
                          type="button"
                          onClick={() => this.salvar()}
                          className="btn btn-success btn-lg waves-effect waves-light"
                          style={{ backgorundColor: "#f2f2f2" }}
                        >
                          Salvar empresa
                        </button>
                      </div>
                    </div>
                  )}
                  {this.state.loading_save == true && (
                    <div className="row">
                      {" "}
                      <div
                        className="col-12 d-flex justify-content-center align-items-center"
                        style={{ marginTop: "2rem" }}
                      >
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  )}

                  {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}
                </div>
              )}

              {this.state.loading_screen == true && (
                <div className="row">
                  {" "}
                  <div
                    className="col-12 d-flex justify-content-center align-items-center"
                    style={{ marginTop: "2rem" }}
                  >
                    <div
                      className="spinner-border text-primary"
                      role="status"
                    ></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapsStateToProps = (state) => ({
  token: state.AppReducer.token,
  permissoes: state.AppReducer.permissoes,
});

export default connect(mapsStateToProps, { logout })(EditarEmpresa);